import {
  Box
} from '@chakra-ui/react';
import { useIsAuthenticated } from '../../store/useAppStore';
const VisitorPage = (props) => {
  const { title, breadCrumbs, children } = props;
  const isAuthenticated = useIsAuthenticated();
  return (
    !isAuthenticated && 
    <Box
    w='100%'
    bgImage='https://aiai-static-assets.s3.amazonaws.com/signin.png'
    bgSize='cover'
    h='full'
    
    bgPos='center center'
  >
    {children}
    </Box>
  );
};

export default VisitorPage;
