import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useIsAuthenticated } from '../../store/useAppStore';


const Logout = () => {
    const navigate = useNavigate();
    const isAuthenticated = useIsAuthenticated();

    useEffect( () => {
      if (isAuthenticated !== null ) {
        if (!isAuthenticated) {
         // navigate(signinUrl);
         window.location.href = ('/signin');
        }
      }
    }, [isAuthenticated])

  return (
    <div />
  );
};

export default Logout;
