/* eslint-disable react/function-component-definition */
import {
  Box,
  Flex,
  Text,
  IconButton,
  Button,
  Stack,
  Collapse,
  Icon,
  Link,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useColorModeValue,
  useColorMode,
  useDisclosure,
  MenuButton,
  Menu,
  MenuList,
  MenuItem,
  Divider,
  useTimeout,
} from '@chakra-ui/react';
import { useEffect } from 'react';

import {
  HamburgerIcon,
  CloseIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  SunIcon,
} from '@chakra-ui/icons';
import { useNavigate, useLocation } from 'react-router-dom';
import { UserCircleIcon } from '@heroicons/react/20/solid';
import { useAppActions, useIsAuthenticated, useUser } from '../../store/useAppStore';

import Logo from './Logo';
import { LinkComp } from '../../components';
import { useNavItems } from './NavItems';

const AccountMenu = () => {
  const linkColor = useColorModeValue('gray.600', 'gray.200');
  const linkHoverColor = useColorModeValue('primary.200', 'primary.500');
  const { signOut } = useAppActions();
  const navigate = useNavigate();
  const user = useUser();
  const isTeacher = user.role.includes('teacher') || user.role.includes('practicing-teacher');
  const isAuthenticated = useIsAuthenticated();


  const handleSignOut =  () => {
    signOut();
    navigate('/logout')
    };

  return (
    <Menu>
      <MenuButton
        // as={IconButton}
        // bg='none'
        // color={useColorModeValue('secondary.200', 'secondary.400')}
        // icon={<UserCircleIcon />}
        fontSize={'xs'}
        width={'100px'}
      >
        {user.firstName} ▼
      </MenuButton>
      <MenuList>
        <MenuItem as={Button} _hover={{ background: linkHoverColor }}>
          <LinkComp to='/account' label='My Account' color={linkColor} />
        </MenuItem>
        {isTeacher && (
          <MenuItem as={Button} _hover={{ background: linkHoverColor }}>
            <LinkComp to='/my-lessons' label='My Lessons' color={linkColor} />
          </MenuItem>
        )}
        <MenuItem as={Button} _hover={{ background: linkHoverColor }}>
            <LinkComp to='/comparisons/OpenSaved' label='Saved Comparisons' color={linkColor} />
          </MenuItem>
        <Divider />
        <MenuItem
          as={Button}
          onClick={handleSignOut}
          color={linkColor}
          _hover={{ background: linkHoverColor }}
        >
          Sign Out{' '}
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

function DesktopNav(props) {

  const {location} = props;
  const page = `/${location.pathname.replace('/','').split('/')[0]}`;
  const linkColor = 'white';
  const linkSelectedColor = 'primary.200';
  const linkHoverColor = 'primary.400';

  const popoverContentBgColor = useColorModeValue('secondary.800', 'gray.800');
  return (
    <Stack direction='row' spacing={4}>
      {useNavItems().map((navItem) => (
        <Box key={navItem.label}>
          <Popover trigger='hover' placement='bottom-start'>
            <PopoverTrigger>
              <Link
                p={2}
                href={navItem.href ?? '#'}
                fontSize='sm'
                fontWeight={500}
                color={page === navItem.href ? linkSelectedColor : linkColor}
                _hover={{
                  textDecoration: 'none',
                  color: linkHoverColor,
                }}
              >
                {navItem.label}
              </Link>
            </PopoverTrigger>

            {navItem.children && (
              <PopoverContent
                border={0}
                boxShadow='xl'
                bg={popoverContentBgColor}
                p={4}
                rounded='xl'
                minW='sm'
              >
                <Stack>
                  {navItem.children.map((child) => (
                    <DesktopSubNav key={child.label} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </Stack>
  );
}

function DesktopSubNav({ label, href, subLabel }) {
  return (
    <Link
      href={href}
      role='group'
      display='block'
      p={2}
      rounded='md'
      _hover={{ bg: useColorModeValue('secondary.700', 'gray.900') }}
    >
      <Stack direction='row' align='center'>
        <Box>
          <Text transition='all .3s ease' _groupHover={{ color: 'primary.500' }} fontWeight={500}>
            {label}
          </Text>
          <Text fontSize='sm'>{subLabel}</Text>
        </Box>
        <Flex
          transition='all .3s ease'
          transform='translateX(-10px)'
          opacity={0}
          _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
          justify='flex-end'
          align='center'
          flex={1}
        >
          <Icon color='primary.500' w={5} h={5} as={ChevronRightIcon} />
        </Flex>
      </Stack>
    </Link>
  );
}

function MobileNav() {
  return (
    <Stack bg={useColorModeValue('white', 'gray.800')} p={4} display={{ md: 'none' }}>
      {useNavItems().map((navItem) => (
        <MobileNavItem key={navItem.label} {...navItem} />
      ))}
    </Stack>
  );
}

function MobileNavItem({ label, children, href }) {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Flex
        py={2}
        as={Link}
        href={href ?? '#'}
        justify='space-between'
        align='center'
        _hover={{
          textDecoration: 'none',
        }}
      >
        <Text fontWeight={600} color={useColorModeValue('gray.600', 'gray.200')}>
          {label}
        </Text>
        {children && (
          <Icon
            as={ChevronDownIcon}
            transition='all .25s ease-in-out'
            transform={isOpen ? 'rotate(180deg)' : ''}
            w={6}
            h={6}
          />
        )}
      </Flex>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: '0!important' }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle='solid'
          borderColor={useColorModeValue('gray.200', 'gray.700')}
          align='start'
        >
          {children &&
            children.map((child) => (
              <Link key={child.label} py={2} href={child.href}>
                {child.label}
              </Link>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
}

const Navbar = () => {
  const { isOpen, onToggle } = useDisclosure();
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();
  const location = useLocation();
  const { toggleColorMode } = useColorMode();
  const gotoSignin = () => {
    navigate('/signin');
  };
  const gotoCreateLesson = () => {
    navigate('/lesson/create');
  };
  return (
    <Box>
      <Flex
        bg={useColorModeValue('secondary.900', 'secondary.950')}
        color='white'
        minH='60px'
        py={{ base: 2 }}
        px={{ base: 4 }}
        borderBottom={1}
        borderStyle='solid'
        borderColor={useColorModeValue('gray.200', 'gray.900')}
        align='center'
      >
        <Flex
          flex={{ base: 1, md: 'auto' }}
          ml={{ base: -2 }}
          display={{ base: 'flex', md: 'none' }}
        >
          <IconButton
            onClick={onToggle}
            icon={isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />}
            variant='ghost'
            aria-label='Toggle Navigation'
          />
        </Flex>

        <Flex flex={{ base: 1 }} justify={{ base: 'center', md: 'start' }} alignItems='center'>
          <Logo />
          {isAuthenticated && (
            <Flex display={{ base: 'none', md: 'flex' }} ml={10}>
              <DesktopNav location={location} />
            </Flex>
          )}
        </Flex>

        <Stack flex={{ base: 1, md: 0 }} justify='flex-end' direction='row' spacing={4}>
          {isAuthenticated && (
            <Button variant='uva' size='sm' mt={1} onClick={gotoCreateLesson}>
              Upload Lesson Video
            </Button>
          )}
          {/* <IconButton onClick={toggleColorMode} bg='none' icon={<SunIcon color='gray.200' />} /> */}
          {isAuthenticated ? (
            <AccountMenu />
          ) : (
            <Button fontSize='sm' variant='uva' onClick={gotoSignin}>
              Sign In
            </Button>
          )}
        </Stack>
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav />
      </Collapse>
    </Box>
  );
};
export default Navbar;
