import { useEffect, useState } from 'react';
import { Box, Button, Flex, HStack, Heading, VStack, Text,
  Modal, ModalOverlay, ModalContent, ModalBody, ModalHeader, ModalCloseButton, ModalFooter  } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { FormInput, FormSelect, FormTextarea, FormRadios } from '../../components/forms/FormElements';

import { ClassSchema } from '../../schemas/orgs.ts';
import { useGetOrganizationById, useUpdateOrganization, useGetOrganizationsByType, useGetTeachers, useDeleteOrganization } from '../AdminOrganizations/components/orgHooks';
import SupportTeam from '../../components/Contacts/SupportTeam';
import { GRADES, SUBJECT_COLUMNS } from '../../constants';
import PageTitle from '../../components/PageTitle/PageTitle';
import trackEvent from '../../utlis/trackEvents';
import { useUser } from '../../store/useAppStore';
import { getParameterByName } from '../../utlis/utils';

// function that takes thats the current school year, ex 2023-24 and returns an array of the previous 2 years the current year and the next two years
// function that takes the current date and returns the next time june 30th comes around in yyyy-mm-dd format
// SET DEFAULT VALUES SO THEY CAN BE CLEARED


const getSchoolYears = () => {
  const currentYear = new Date().getFullYear();
  const years = [];
  // eslint-disable-next-line no-plusplus
  for (let i = -2; i < 3; i++) {
    const val = `${currentYear + i}-${currentYear + i + 1}`;
    years.push({ label: val, value: val });
  }
  return years;
};
const getNextJune30FromYear = (yearRange) => {
  const year = yearRange.split('-')[1];
  const nextJune30 = new Date(year, 5, 30);
  return nextJune30.toISOString().split('T')[0];
}

const EditClass = () => {
  const [pageLoaded, setPageLoaded] = useState(false);
  const backUrl = getParameterByName('from');
  const [selectSubjectOpen, setSelectSubjectOpen] = useState(false);
  const [confimrationModal, setConfirmationModal] = useState(false);
  const { data: organizations } = useGetOrganizationsByType({
    orgType: ['institute', 'district', 'school'],
  });
  const { data: teachers } = useGetTeachers();
  const { mutate: deleteOrg } = useDeleteOrganization();

  const [searchParams, setSearchParams] = useSearchParams();
  const orgId = searchParams.get('id');
  const user = useUser();
  
  const updateOrg = useUpdateOrganization()

  const { data: classData } = useGetOrganizationById(orgId);
  if (classData?.userId) classData.userId = {
    _id: classData?.userInfo[0]?._id,
    firstName: classData?.userInfo[0]?.firstName,
    lastName: classData?.userInfo[0]?.lastName,
  }

  const thisClassOrgData = []
  if (classData?.firstParent) {
    thisClassOrgData.push(classData.firstParent)
    thisClassOrgData.push(classData.secondParent)
    thisClassOrgData.push(classData.thirdParent)
  }
  if (thisClassOrgData.length > 0) {
    const thisInstitute = thisClassOrgData.find((el) => el?.type === 'institute');
    const thisDistrict = thisClassOrgData.find((el) => el?.type === 'district');
    const thisSchool = thisClassOrgData.find((el) => el?.type === 'school');
    if (thisInstitute) classData.institute = thisInstitute;
    if (thisDistrict) classData.district = thisDistrict;
    if (thisSchool) classData.parent = thisSchool;
  }
  if (classData?.schoolYear && !classData?.schoolYear?.value) {
    classData.schoolYear = { label: classData.schoolYear, value: classData.schoolYear };
  }
  if (classData?.grade && !classData?.grade?.value) {
    classData.grade = GRADES.find((el) => el.value === classData.grade);
  }
  // if (classData?.subject && !classData?.subject?.value) {
  //   classData.subject = SUBJECTS.find((el) => el.value === classData.subject);
  // }
  // if (classData?.subject ) {
  //   classData.subject = SUBJECT_COLUMNS.find((el) => el.value === classData.subject);
  // }
  const { handleSubmit, formState, register, watch, control, setValue, reset } = useForm(
    {
      defaultValues: classData,
      resolver: zodResolver(ClassSchema),
    },
    [classData],
  );

  const institute = watch('institute');
  const district = watch('district');


  const subject = watch('subject');
  useEffect(() => {

    if (selectSubjectOpen) {
      setSelectSubjectOpen(false);
    }
  }, [subject])
  
  
  const instituteFilter = (options, field) => {
    // options.unshift({ _id: '', name: '', type: 'institute' })
    const institutes = options.filter((el) => el.type === 'institute')
    institutes.unshift({ _id: '', name: 'Not Applicable', type: 'institute' })
    return institutes;
  };

  const descriptionLength = watch('description');

  const districtFilter = (options, field) => {
    const districts = options.filter((el) => el.type === 'district')
    districts.unshift({ _id: '', name: 'Not Applicable', type: 'district' })
    const filteredDistricts = districts.filter((el) => el?.parent === institute?._id)
    filteredDistricts.unshift({ _id: '', name: 'Not Applicable', type: 'district' })
    if (!institute?._id) return districts;
    return filteredDistricts;
  };
  const schoolFilter = (options, field) => {
    // options.unshift({ _id: '', name: '', type: 'school' })
    const schools = options.filter(
      (el) => el.type === 'school' && (el?.parent === institute?._id || el?.parent === district?._id),
    )
    schools.unshift({ _id: '', name: '', type: 'school' })
    if (!institute?._id && !district?._id) return [];
    return schools
  };
  useEffect(() => {
    reset(classData);
  }, [classData, reset]);

  const navigate = useNavigate();
  const cancel = () => {
    const navTo = backUrl === 'dashboard' ? '/' : '/organizations';
    navigate(navTo);
  };

  const updateClass = (data) => {
    data._id = orgId;
    trackEvent({
      page: 'Edit Class',
      params: '',
      event: 'Edit Class',
      me: user,
      description: 'Edit Class',
      details: `Edited Class: ${data.name}`,
    })
    updateOrg.mutate(data)
  }

  const handleDelete = () => {
    deleteOrg(orgId);
    setConfirmationModal(false);
    cancel();
  };
  
  const renderClassForm = () => (
    <Box
      as='form'
      id='create-district'
      onSubmit={handleSubmit(updateClass)}
      display='flex'
      flexDirection='column'
      gap={4}
      maxW={900}
      mx={8}
      mb={12}
    >
      <FormSelect
        name='userId'
        label='Practicing Teacher / Teacher Candidate'
        options={teachers}
        control={control}
        register={register}
        errors={formState.errors}
        isDisabled
        key='teacher'
        getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
        getOptionValue={(option) => option}
        width={400}
      />
      <FormInput
        width={500}
        name='name'
        label='Class Name'
        register={register}
        errors={formState.errors}
      />

      <FormTextarea
        name='description'
        label='Description'
        register={register}
        errors={formState.errors}
        helperText={`${descriptionLength?.length || 0}/450`}
        maxLength={450}
        width={800}
      />
      <HStack gap={4}>
        <FormSelect
          name='grade'
          label='Grade'
          options={GRADES}
          control={control}
          register={register}
          errors={formState.errors}
          width={275}
          key='grade'
        />
        <FormInput
          width={275}
          name='period'
          label='Period'
          register={register}
          errors={formState.errors}
        />
        {/* <FormSelect
          width={275}
          name='subject'
          label='Subject'
          options={SUBJECTS}
          control={control}
          register={register}
          errors={formState.errors}
          key='subject'
        /> */}
         {selectSubjectOpen
        ?
        <Modal isOpen={selectSubjectOpen} onClose={() => {setSelectSubjectOpen(false)}} size='xl'>
        <ModalOverlay />
        <ModalContent maxW="1100px">
          <ModalHeader>Select a Class Subject</ModalHeader>
          <ModalCloseButton />
          <ModalBody  pl={12}>
                <FormRadios
                  width={275}
                  name='subject'
                  label='Subject'
                  options={SUBJECT_COLUMNS}
                  control={control}
                  register={register}
                  errors={formState.errors}
                  key='subject'
                  selectedSubject={subject}
                />
              </ModalBody>
          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={() => {setSelectSubjectOpen(false)}}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
        :
        <VStack align='stretch'  mt={'-7px'} spacing={2}>
          <Text fontSize={'sm'}>Subject</Text>
            <HStack >
              <Text>{subject}</Text>
            </HStack>
        </VStack>
        }
      </HStack>
      <HStack gap={4}>
        <FormInput
          width={275}
          name='subjectId'
          label='Subject ID'
          register={register}
          errors={formState.errors}
          isDisabled
        />
        <FormInput
          width={275}
          name='classId'
          label='Class ID'
          register={register}
          errors={formState.errors}
          isDisabled
        />
        <FormInput
          width={275}
          name='districtId'
          label='District ID'
          register={register}
          errors={formState.errors}
          isDisabled
        />
      </HStack>
      <Flex gap={4} flexWrap='wrap' flexDirection='row'>
      <FormSelect
          name='institute'
          width={275}
          label='Teacher Preparation Institution'
          options={organizations}
          filter={instituteFilter}
          control={control}
          register={register}
          errors={formState.errors}
          key='institute'
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option}
        />
        <FormSelect
          name='district'
          width={275}
          label='District (optional)'
          options={organizations}
          filter={districtFilter}
          control={control}
          register={register}
          errors={formState.errors}
          key='district'
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option}
          noOptionsMessage={() => 'Select a Teacher Preparation Institution first'}
        />
        <FormSelect
          name='parent'
          label='School'
          width={275}
          options={organizations}
          filter={schoolFilter}
          control={control}
          register={register}
          errors={formState.errors}
          key='school'
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option}
          noOptionsMessage={() => 'Select a Teacher Preparation Institution or district first'}
        />

        <FormSelect
          name='schoolYear'
          label='School Year'
          options={getSchoolYears()}
          control={control}
          register={register}
          errors={formState.errors}
          // defaultValue={getSchoolYears().find((el) => el.value === classData?.schoolYear)}
          onChange={(e) => {
            setValue('endDate', getNextJune30FromYear(e.value));
            setValue('schoolYear', e);
          }}
          width={275}
          key='school-year'
        />
        <FormInput
          width={275}
          name='term'
          label='Term'
          register={register}
          errors={formState.errors}
        />
        <FormInput
          width={275}
          name='endDate'
          label='Lesson End Date'
          type='date'
          register={register}
          errors={formState.errors}
        />
        </Flex>
        <SupportTeam setValue={setValue} watch={watch} savedTeam={classData?.supportTeam}/>
        <Flex gap={4}>
        <Button variant='uva-outline' onClick={cancel}>
          Cancel
        </Button>
        <Button variant='uva' type='submit'>
          Update Class
        </Button>
        <Button variant='solid' colorScheme='red' onClick={() => setConfirmationModal(true)}>
          Delete Class
        </Button>
        </Flex>
      {/* SUPPORT MEMBERS */}
    </Box>
  );
  return (
    <>
       <PageTitle title='Edit Class' />
      {renderClassForm()}
      <Modal isOpen={confimrationModal} onClose={() => setConfirmationModal(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <Text pt={6}>Are you sure you want to delete {classData?.name}? This cannot be undone. This could impact who has access to Lessons.</Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme='red' mr={3} onClick={() => handleDelete()}>
              Delete
            </Button>
            <Button variant='ghost' onClick={() => setConfirmationModal(false)}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
EditClass.propTypes = {};
export default EditClass;
