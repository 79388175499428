import React, { useState, useEffect } from 'react';

import { Text, HStack, Box, Select, Button } from '@chakra-ui/react';
import moment from 'moment';
import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
} from "@choc-ui/chakra-autocomplete";

function CompareSelect(props) {
  const { isNew, index, max, setSelection, list = [], isTeacher, isAdmin, hasShares, lessonId } = props;
  const [teacher, setTeacher] = useState('');
  const [preSelectedTeacher, setPreSelectedTeacher] = useState('');
  const [teacherSelectReady, setTeacherSelectReady] = useState(false);
  const [teacherList, setTeacherList] = useState([])
  const [subject, setSubject] = useState('');
  const [lesson, setLesson] = useState('');
  const [subjectList, setSubjectList] = useState([]);
  const subjectPrompt = 'Select Subject';
  const lessonPrompt = 'Select Lesson';
  const [lessonList, setLessonList] = useState([]);
  // const [pickerItems, setPickerItems = useState([]); 
    
  const setLessons = (teacherId, subject) => {
      let lessons = [];
      list.forEach(l => {
        if (l._id.teacher === teacherId && (subject === 'All' || l._id.subject === subject)) {
          lessons = [...lessons, ...l.lessons];
        }
      })
      setLessonList(lessons);
  }
 
  const setTeachersSubjects = (teacherId) => {
    const subs = [];
    list.forEach((l) => {
      if (l._id.teacher === teacherId) {
        subs.push(l._id.subject);
      }
    })
    setSubjectList(subs);
    if (subs.length === 1) {
      setLessons(teacherId, subs[0]);
    }
  }


  const selectSubject = (teacherId, subject) => {
    setSubject(subject);
    let lessons = [];
    list.forEach(l => {
      if (l._id.teacher === teacherId && (subject === 'All' || l._id.subject === subject)) {
        lessons = [...lessons, ...l.lessons];
      }
    })
    setLessonList(lessons);
  }

  const onTeacherSelect = (item) => {
    const tIindex = item.item.originalValue;
    setTeacher(teacherList[tIindex]);  // the numerical value of the index
    setSubject('');
    setLessonList([]);
    setLesson('');
    setTeachersSubjects(teacherList[tIindex]._id);
    setSelection(index, null, 'started'); // indicate started this row (but not a complete row)
  }

  const onChangeSubject = (target) => {
    const s = target.value;
    setLesson('');
    selectSubject(teacher._id, s);
    setSelection(index, lesson, 'started'); // indicate started this row (but not a complete row)
  }

  const onChangeLesson = (target) => {
    const l = target.value;
    setLesson(l); 
    setSelection(index, l, 'add'); // add a lesson;
  }

  const removeLesson = () => {
    setSelection(index, lesson, 'delete'); // remove this lesson
  }


  useEffect(() => {
    if (list.length > 0) {
      let foundLesson = false;
      let foundTeacher = false;
      const tids = [];
      const tl = [];
      list.filter(lt => lt.teacherInfo).forEach(l => {
        if( !tids.includes(l._id.teacher)) {
          tids.push(l._id.teacher);
          tl.push(l.teacherInfo);
        }
        if (lessonId) {
          // find the selected lesson
          l.lessons.forEach(lesson => {
            if (lesson.lessonId === lessonId) {
              const inew = isNew;
              const idx = index;
              foundLesson = lesson;
              setLessons(l._id.teacher, l._id.subject);
              setLesson(lessonId);
              setSubject(l._id.subject);
              setTeacher(l._id.teacher);

              setTeacher(l.teacherInfo);  // the numerical value of the index
              foundTeacher = l.teacherInfo;
              setTeachersSubjects(l._id.teacher);
              setPreSelectedTeacher(`${foundTeacher.firstName} ${foundTeacher.lastName}`);
           
            }
          })
        }
      })
      setTeacherList(tl);
      // const pickers = tl.map((teacher) => ({
      //   value: teacher._id,
      //   label: `${teacher.firstName} ${teacher.lastName} (schoolname)`,
      // }));
      // setPickerItems(pickers);
  
      if (foundTeacher) {
        setTeacher(foundTeacher);
      }
     // selectTeacher(list[0]._id.teacher);
      // selectSubject(list[0]._id.teacher, 'All');
      setTeacherSelectReady(true);
    }
  }, [list]);



  const renderTeacherSelect = () => {
  const t = teacherList;

    return(
      <>
      {index === 0 && (
        <Text fontSize={'sm'} ml={1} mb={2} fontWeight={'bold'}>Teacher</Text>
      )}
    <AutoComplete 
      openOnFocus
      onSelectOption={onTeacherSelect}
      defaultValue={preSelectedTeacher}
      >
    <AutoCompleteInput 
      variant="filled" 
      placeholder="Select Teacher..." 
      autoComplete="off" 
      type='text'
      />
    <AutoCompleteList>
      {teacherList.map((teacherInfo, cid) => (
        <AutoCompleteItem
          key={`option-${cid}`}
          value={cid} // {`${teacherInfo.firstName} ${teacherInfo.lastName}`}
          textTransform="capitalize"
          label={`${teacherInfo.firstName} ${teacherInfo.lastName}`}
        >
         {teacherInfo.firstName} {teacherInfo.lastName}
        </AutoCompleteItem>
      ))}
    </AutoCompleteList>
  </AutoComplete>
  </>
  )
}

const renderSubjectSelect = () => {

  const sl = subjectList;
  if ( subjectList.length === 0) {
    return(null)
  }

  if (subjectList.length === 1) {
    return(
      <>
         {index === 0 && (
        <Text fontSize={'sm'} fontWeight={'bold'} ml={1} mb={2}>Subject</Text>
      )}
      <Text  w='200px'>{subjectList[0]}</Text>
      </>
    )
  }

  return (
    <>
      {index === 0 && (
        <Text fontSize={'sm'} fontWeight={'bold'} ml={1} mb={2}>Subject</Text>
      )}
    <Select 
      placeholder={subject ? null : subjectPrompt}
      onChange={e => onChangeSubject(e.target)}
      value={subject}
      width="200px"
      >
      <option value='All'>All</option>
      { subjectList.map((sub) => (
           <option key={`subjects-${sub}-${Math.random()}`} value={sub}>
            {sub}
            </option>
        ))}
    </Select>
    </>
  )
}

const renderLessonSelect = () => {
  if (lessonList.length === 0) {
    return null;
  }

  return (
    <>
       {index === 0 && (
        <Text fontSize={'sm'} ml={1} fontWeight={'bold'} mb={2}>Lesson</Text>
      )}
    <Select
      placeholder={lesson ? null : lessonPrompt}
      onChange={e => onChangeLesson(e.target)}
      value={lesson}
    >
      {lessonList.map((l) => (
        <option key={`lessons-${l.name}-${Math.random()}`} value={l.lessonId}>
          {l.classroom && (
            <b><i>Grade {l.classroom.grade} {l.classroom.period ? `, Period ${l.classroom.period}: ` : ': '}</i></b>
          )}
          {l.name} ({moment(l.date).format('M/D/YY')})
        </option>
      ))}
    </Select>
    </>
  )
}
  
  return (
    <div>
      <HStack spacing='8'>
        <Box w={'250px'}>
          {!isAdmin && (isTeacher && !hasShares)
          ?
          <Text>My Classes</Text>
          :
          <div>
            {teacherSelectReady && renderTeacherSelect()}
          </div>
        }
        </Box>
        <Box>
          {renderSubjectSelect()}
        </Box>
        <Box>
          {renderLessonSelect()}
        </Box>
        {lesson && <Box
           mt={index === 0 ? 6 : 0}>
          
         <Button 
          size={'xs'}
          onClick={removeLesson}
          >
          REMOVE
          </Button>

        </Box>
          }
   
      </HStack>
    </div>
  );
}

export default CompareSelect;