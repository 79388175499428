/* eslint-disable vars-on-top */
/* eslint-disable no-nested-ternary */
import { useState, useLayoutEffect } from 'react';
import { Text } from '@chakra-ui/react';
import moment from 'moment';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from "@amcharts/amcharts5/xy";

// eslint-disable-next-line camelcase
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';

import { ACTIVITY_TYPES, ACTIVITY_COLORS } from '../../constants';

const ActivityCompareGraph = ({ lessons, activity }) => {
  const chartDivName = `activity-chart-${Math.random()}`;
  const [displayChart, setDisplayChart] = useState(true);

  useLayoutEffect(() => {
    if (!document.getElementById(chartDivName)) {
      return;
    }
    am5.addLicense("AM5C450941845");
    
    // Create root element
    const root = am5.Root.new(chartDivName);

    // set the theme
    // eslint-disable-next-line camelcase
    root.setThemes([am5themes_Animated.new(root)]);

    const chartData = [];

    let names = [];
    lessons.forEach(lesson => {
      const name = `${lesson.name} (${moment(lesson.lessonData).format('MM/DD/YYYY')})`
      const minutes = lesson.lessonActivities[activity].length ? (lesson.lessonActivities[activity].length/60) : 0;
      names.push(name);
      chartData.push({
        category: name,
        value2: parseFloat(minutes.toFixed(2)),
        value3: lesson.lessonActivities[activity].numMoments || 0,
  
      })
    })
     
    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: true,
        panY: false,
        wheelX: "none",
        wheelY: "true",
        arrangeTooltips: false,
        pinchZoomX: true
      })
    );

    // make y axes stack
    chart.leftAxesContainer.set("layout", root.verticalLayout);

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    let xRenderer = am5xy.AxisRendererX.new(root, { minGridDistance: 70 });
    xRenderer.labels.template.setAll({
      paddingTop: 20,
      maxWidth: 150,
      oversizedBehavior: "wrap",
      textAlign: "center",
      fontSize: 14,
    });


    xRenderer.grid.template.set("location", 0.5);

    // xRenderer.grid.template.set("location", 0.5);

    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "category",
        tooltip: am5.Tooltip.new(root, {}),
        renderer: xRenderer
      })
    );

    xAxis.data.setAll(chartData);

    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    function createSeries(field, margin, column) {
      const yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: am5xy.AxisRendererY.new(root, {}),
          tooltip: am5.Tooltip.new(root, {
            animationDuration: 0
          }),
          x: am5.p100,
          centerX: am5.p100,
          marginTop: margin, // this makes gap between axes
        })
      );

      const label1 = am5.Label.new(root, {
        rotation: -90,
        text: column ? 'Moments' : 'Total Minutes',
        y: am5.p50,
        centerX: am5.p50
        //x: am5.p0,
        //centerY: am5.p0
      })
      
      yAxis.children.unshift(
        label1
      );
      

      let series;
      if (column) {
        series = chart.series.push(
          am5xy.ColumnSeries.new(root, {
            xAxis,
            yAxis,
            valueYField: field,
            categoryXField: "category",
            sequencedInterpolation: true,
            tooltip: am5.Tooltip.new(root, {
              pointerOrientation: "vertical",
              labelText: "{valueY} moments"
            }),
          })
        );
        series.columns.template.setAll({
          width: am5.percent(25)
        });

      } else {
        series = chart.series.push(
          am5xy.LineSeries.new(root, {
            xAxis,
            yAxis,
            valueYField: field,
            categoryXField: "category",
            sequencedInterpolation: true,
            tooltip: am5.Tooltip.new(root, {
              pointerOrientation: "vertical",
              labelText: `{valueY} minutes`
            })
          })
        );
      }

      if (!column) {
        series.bullets.push(() => am5.Bullet.new(root, {
            locationY: 1,
            locationX: 0.5,
            sprite: am5.Circle.new(root, {
              radius: 4,
              fill: series.get("fill")
            })
          }));
      }

      series.set("fill", am5.color(ACTIVITY_COLORS[activity].bg));
      series.set("stroke", am5.color(ACTIVITY_COLORS[activity].bg));

      series.data.setAll(chartData);
      series.appear();

      return series;
    }

    createSeries("value2", 40, false);
    createSeries("value3", 40, true);
    // Add cursor
    // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
    let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
      behavior: "none",
      xAxis: xAxis
    }));

  
    chart.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, [lessons, activity, chartDivName]);

  return (
    <div>
      {displayChart ? <div id={chartDivName} style={{ width: 'calc(100vw - 500px)', height: 500 }} /> : <Text width='200px'>No Data Available</Text>}
    </div>
  );
};

export default ActivityCompareGraph;

