import {
  Box,
  Button,
  Center,
  Heading,
  Text,
  VStack,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { useAppActions, useIsAuthenticated, useRedirectStore, useUser } from '../../store/useAppStore';
import { LinkComp } from '../../components';
import api from '../../queries/api';
import Form from '../../components/forms/Form';
import { FormInput } from '../../components/forms/FormElements';
import { SignInSchema } from '../../schemas/users.ts';
import { useContactActions } from '../../store/useContactStore';
import VisitorPage from '../../components/VisitorPage/VisitorPage';
import trackEvent from '../../utlis/trackEvents';

const SignIn = () => {
  const navigate = useNavigate();

  const {redirectLocation, redirectActions} = useRedirectStore();
  const { authenticateUser } = useAppActions();
  const isAuthenticated = useIsAuthenticated();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const { setContactList } = useContactActions();
  const [settings, setSettings] = useState(null);

  const getSettings = async () => {
    const newSettings = await api.get('/info/settings?key=messages');
      setSettings(newSettings);
  }

  useEffect(() => {
    if (isAuthenticated && redirectLocation) {
      navigate(redirectLocation, { replace: true });
      redirectActions.clearRedirect();
    } else if(isAuthenticated) {
      navigate('/', { replace: true });
    }
  }, [isAuthenticated, redirectLocation, redirectActions, navigate]);

  useEffect(() => {
    getSettings();
  }, []);

  const handleSignIn = async (data) => {
    setIsLoading(true);
    try {
      const response = await api.post('/users/auth/signin', data);
      if (response?.data?.token) {
        authenticateUser(response.data);
        trackEvent({
          page: 'Sign In',
          params: '',
          event: 'Sign In',
          me: response.data,
          description: redirectLocation ? `Deep link to ${redirectLocation}` : 'Standard',
          details: '',
          value: 0,
        })
        if (response.data.contacts) {
          setContactList(response.data.contacts);
        }
        setIsLoading(false);
        return null;
      }
      throw new Error(response.errorMessage);
    } catch (err) {
      console.log(err);
      toast({
        title: 'Error Signing In',
        description: err?.response?.data?.message || err.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      setIsLoading(false);
      return null;
    }
  };
  return (
    <VisitorPage>
      <Center h='full' w='full'>
        <VStack
          w='md'
          bg={useColorModeValue('whiteAlpha.800', 'blackAlpha.100')}
          borderRadius='lg'
          gap='3'
          shadow='md'
          pb='2rem'
        >
          <Heading
            as='h2'
            size='lg'
            pt='4'
            color={useColorModeValue('secondary.900', 'secondary.100')}
          >
            Sign in to AIAI
          </Heading>
          <Form
            onSubmit={handleSignIn}
            id='signin'
            resolver={zodResolver(SignInSchema)}
            w='md'
            display='flex'
            flexDirection='column'
            gap={4}
            px='16'
            py='4'
          >
            <FormInput name='email' label='Email' type='email' />
            <FormInput name='password' label='Password' type='password' />
          </Form>
          <Text fontSize='sm'>
            <LinkComp to='/recover' label='Forgot Password?' />
          </Text>
          <Button type='submit' value='submit' form='signin' variant='uva' isLoading={isLoading}>
            Sign In
          </Button>
          <Text fontSize='sm' textAlign='center'>Having trouble logging in? Please contact AIAI Support at <a href = "mailto: aiaiproject@virginia.edu">aiaiproject@virginia.edu</a>.</Text>
        </VStack>
      </Center>
    </VisitorPage>
  );
};
export default SignIn;
