import axios from 'axios';
import { API_URL } from '../constants';
import useAppStore from '../store/useAppStore';

const api = axios.create({
  baseURL: API_URL,
  timeout: 24000,
});
// IF THERE IS A TOKEN AND USER IS AUTHENTICATED, ADD IT TO EACH REQUEST
api.interceptors.request.use(
  (config) => {
    const { token, isAuthenticated } = useAppStore.getState();
    const updatedConfig = { ...config };
    if (token && isAuthenticated) {
      updatedConfig.headers.token = token;
    }
    return updatedConfig;
  },
  (error) => error,
);
// CATCH IMPORTANT ERRORS HERE
api.interceptors.response.use(
  (response) => response,
  (error) => {
    const store = useAppStore.getState();
    if (error?.response?.status === 401 && error?.response?.data?.msg.includes('Token expired.')) {
      store.actions.signOut();
      window.location.replace('/logout');
    }
    return Promise.reject(error);
  },
);

export default api;
