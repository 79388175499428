import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useToast } from '@chakra-ui/react';
import axios from 'axios';
import { STAGE } from '../../../constants';
import api from '../../../queries/api';
// eslint-disable-next-line import/prefer-default-export
export const useCreateLesson = (reset) => {
  const queryClient = useQueryClient();
  const toast = useToast();
  const mutation = useMutation({
    mutationFn: async (newLessonData) => {
      const { data, handleProgress } = newLessonData;
      const uploadFile = data.file;
      delete data.file;
      const createResponse = await api.post('/lessons/new', data);
      const payload = {
        key: `uploads/${STAGE === 'prod' ? createResponse.data.lesson.lessonKey : `stage_${createResponse.data.lesson.lessonKey}`}`,
        name: data.name,
        lessonDate: data.lessonDate,
        mime: uploadFile.type,
        lessonId: createResponse.data.lesson.lesson.insertedId,
        classId: data.class,
      };
      const uploadUrl = await api.post('/lessons/getSignedUrl', payload);
      
      const config = {
        onUploadProgress: (ev) => handleProgress(ev),
        transformRequest: [
          (data, headers) => {
            // eslint-disable-next-line no-param-reassign
            delete headers.Authorization;
            return data;
          },
        ],
  
      };
      const uploadResponse = await axios.put(uploadUrl.data.url, uploadFile, {
        ...config,
        headers: {
          'Content-Type': uploadFile.type, // Make sure this matches the videoType used in getSignedPutUrl
        },
      });
      if (uploadResponse.status === 200) {
        await api.get(`/lessons/completeUpload/${createResponse.data.lesson.lesson.insertedId}`)
      }
      return uploadResponse.data;
    },
    onSuccess: async () => {
      toast({
        title: 'Success',
        description: 'Lesson created successfully.',
        status: 'success',
        duration: 6000,
        isClosable: true,
      });
      queryClient.invalidateQueries('lessons');
      reset();
    },
    onError: (err) => {
      toast({
        title: 'Something went wrong.',
        description: err.response.data.msg === 'Lesson name already exists' ? 
        'A lesson with this name already exists. Please use a different lesson name.' : 
        'There was an error creating this lesson. Please try again.',
        status: 'error',
        duration: 6000,
        isClosable: true,
      });
      reset();
    },
  });
  return { createNewLesson: mutation };
}
